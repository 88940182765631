
import Basket from 'icons/basket-cart--desktop.svg?inline'
import RPHInput from 'rph/atoms/RPHInput/index.vue'

import MinusIcon from '~/assets/svg/minus.svg?inline'
import PlusIcon from '~/assets/svg/plus.svg?inline'
import PlusIconSmall from '~/assets/svg/plus-small.svg?inline'
import MinusIconSmall from '~/assets/svg/minus-small.svg?inline'
import counterMixin from '@/mixins/counterMixin'
export default {
  name: 'ProductCounter',
  components: {
    RPHInput,
    MinusIcon,
    PlusIcon,
    PlusIconSmall,
    MinusIconSmall,
    Basket,
  },
  mixins: [counterMixin],
}
