
/**
 * @vue-prop {String} type - heading type
 */
export default {
  name: 'HeadingComponent',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
