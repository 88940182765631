
/**
 * @vue-prop {String} type - btn type ['primary','secondary', 'standart', 'outlined-small']
 * @vue-prop {String} rightIcon - append icon
 * @vue-prop {String} leftIcon - prepend icon
 * @vue-prop {String} disabled - btn active
 * @vue-prop {String} loading - btn status
 * @vue-computed {String} computedClass - styled class for current btn
 */
export default {
  name: 'ButtonComponent',
  props: {
    type: {
      type: String,
      default: '',
    },
    rightIcon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    btnType: {
      type: String,
      default: 'button',
    },
    iconWidth: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    computedClass() {
      switch (this.type) {
        case 'primary':
          return `btn-primary`
        case 'secondary':
          return `btn-secondary`
        case 'standart':
          return `btn-standart`
        case 'icon':
          return `btn-icon`
        case 'outlined-small':
          return `btn-outlined-small`
        default:
          return ''
      }
    },
  },
}
