
import Vue from 'vue'
import VueMask from 'v-mask'

Vue.use(VueMask)
/**
 * @vue-prop {String} type - input type ['text','tel', 'email', 'password','search', 'standart']
 * @vue-prop {String} label - input label
 * @vue-prop {String} placeholder - input placeholder
 * @vue-prop {String} error - input error
 * @vue-prop {String} value - input value
 * @vue-prop {Boolean} readonly - input readonly
 * @vue-prop {Boolean} disabled - input disabled
 * @vue-prop {String} message - input message
 * @vue-prop {Boolean} noFormGroup - delete 'form-group' class if true
 */

export default {
  name: 'InputComponent',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    dataCp: {
      type: String,
      default: null,
    },
    inputClass: {
      type: String,
      default: '',
    },
    errorAbsolute: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    failed: {
      type: Boolean,
      default: false,
    },
    passed: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    mask: {
      type: [String, Number],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withCheck: {
      type: Boolean,
      default: false,
    },
    withEye: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    noFormGroup: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    /**
     * Method emits input value
     * @param {Event} event input event
     */
    onInput(event) {
      const value = event.target.value
      this.$emit('input', value)
    },
  },
}
