
// Vuex
import { mapGetters } from 'vuex'
/**
 * @vue-computed {Number} productsPriceSum - cart total products sum
 */
export default {
  name: 'Basket',
  data: () => ({}),
  computed: {
    ...mapGetters({
      CartProducts: 'cart/CartProducts',
    }),
    productsPriceSum() {
      return this.CartProducts.reduce((acc, item) => {
        return item.disabled
          ? (acc += 0)
          : !(acc += item['exp-price'])
            ? (acc += item.price)
            : (acc += item.price)
      }, 0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    availableCartProducts() {
      return this.CartProducts.reduce((acc, item) => {
        return (acc += item.disabled ? 0 : 1)
      }, 0)
    },
  },
}
